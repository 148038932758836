body {
    color: #414141e8 !important;
    font-family: "Helvetica" !important;
}

h6 {
    color: #3E3E3E;
}

.font-xsmall {
    font-size: 10px;
}

.container-height {
    min-height: calc(100vh - 70px);
}

.rounded {
    border-radius: 8px !important;
}

p {
    font-size: 14px;
}

span {
    font-size: 14px;
}

label {
    font-size: 16px;
}

textarea {
    resize: none;
}

.btn-outline-dark {
    border-color: #7c7979 !important;
}

.form-control:focus, select:focus {
    border-color: #d2d5d8 !important;
    box-shadow: none !important;
}

.bg-primary {
    background-color: #3174c5 !important;
}

.btn-primary {
    background-color: #3174c5 !important;
    border-color: #3174c5 !important;
}

/*.btn-secondary {*/
/*    color: #fff;*/
/*    background-color: #0f0501;*/
/*    border-color: #0f0501;*/
/*}*/

.btn-primary.focus, .btn-primary:focus {
    box-shadow: none;
}

.opacity-0 {
    opacity: 0;
}

.opacity-1 {
    opacity: 1 !important;
}

#modalHeader .modal-body {
    height: 90vh !important;
}

#modalHeader .list-group-item {
    font-size: x-large;
    padding: 0;
    border: none;
}

#modalHeader svg {
    margin-top: -5px !important;
}

#modalHeader .list-group-item:focus {
    border: solid 1px #dc3545;
}

.wideList {
    padding: .75rem 0;
}

#modalHeader hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(102, 102, 102, 0.75), rgba(0, 0, 0, 0));
}

.HeaderNav {
    padding-bottom: 80px;
}

.nav-link {
    padding: .4rem;
}

.nav-link svg {
    margin-top: -6px;
}

#modalHeader .modal-header {
    border-bottom: none;
}

#modalHeader span, #modalHeader p {
    font-size: x-large;
}

#modalHeader .badge {
    font-size: large;
    line-height: initial;
}

.HeaderNav .badge {
    font-size: x-small;
    margin-left: -10px;
    line-height: inherit;
}

#modalHeader .modal-header span {
    border: solid 1px;
    border-radius: 20px;
    padding: 4px 11px 5px;
}

#modalHeader .modal-content {
    background-color: #222222f2;
}

#modalHeader .modal-content .list-group-item {
    background-color: transparent;
}

#modalHeader .modal-content a {
    color: rgba(255, 255, 255, 0.70);
}

#modalHeader .modal-header span {
    color: #505050;
    background-color: #fff;
}

.footer {
    border-top: solid 1px #3174c5;
}

.footer span {
    display: inline-block;
    padding: .15em .4em .20em;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 50%;
    margin-top: -1.3rem !important;
}

li.messages-hostal-list span {
    display: inline-block;
    padding: .15em .4em .20em;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 50%;
    margin-top: -1.3rem !important;
}

.footer, .footer a {
    background-color: #ffffff !important;
    color: #3174c5 !important;
}

.footer a:hover {
    color: #29569d !important;
}

.input-group-text {
    background-color: #fff !important;
    /*padding: 0;*/
    /*border-right: none;*/
}

#formPhone, #formPrice {
    border-left: none;
    padding-left: 0 !important;
    margin-left: -2px !important;
}

.mediumIconCol {
    max-width: 60px !important;
}

.mediumIconColDashboard {
    max-width: 50px !important;
}

.vai {
    display: flex;
    vertical-align: middle;
}

.borderedIcon {
    border: solid 1px !important;
    padding: 8px !important;
    border-radius: 20%;
}

.backgroundIconDefault {
    background-color: #efefef;
}

.card {
    border-radius: 8px;
}

.card-body {
    padding: .7rem !important;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    border: #b8b8b8 solid 1px;
    border-radius: 8px;
}

.chatBubbleLeft {
    margin: 5px 5px 5px 17px;
    border-left: 1px solid #adb5bd;
}

.chatBubbleRight {
    margin: 10px 7px 5px 5px;
    border-right: 1px solid #adb5bd;
}

.cardBookmark {
    margin-top: -1.5rem !important;
    margin-right: .6rem !important;
}

.ListGroupNoBorders {
    border-width: 0 0 0 0 !important;
}

#countryCode .input-group-prepend {
    margin-right: -9px;
}

.custom_select {
    flex: 1 1 auto;
}

.text-muted, .form-label {
    color: #9B9B9B !important;
}

.text-decoration-underline {
    text-decoration: underline;
}

.custom_select > div {
    border-bottom-left-radius: unset;
    border-top-left-radius: unset;
}

/*.custom_select :nth-child(3) :nth-child(2)::after {*/
/*.custom_select :nth-child(3) .second-child::after {*/
/*content: '';*/
/*position: absolute;*/
/*left: 10px;*/
/*right: 10px;*/
/*top: 83px;*/
/*z-index: 1;*/
/*border-bottom: 1px dashed #9B9B9B;*/
/*}*/

.phone_info .flag-icon {
    width: 60px;
    height: 40px;
    line-height: 2.9em;
}

.text-right a {
    line-height: 2.7em;
}

.PhotoHostalmargin {
    margin-top: -2.3rem !important;
}

.PhotoHostalmargin {
    margin-top: -3.5rem !important;
}

.PhotoHostalCloseButton span {
    background-color: rgba(0, 0, 0, 0.80);
    font-size: 30px !important;
    font-weight: lighter;
    border-radius: 30px;
    display: inline-table;
    padding: 4px 11px 4px 10px;
    color: #d2d5d8;
    text-shadow: 0 0;
    opacity: 0.9 !important;
}

.PhotoHostalCloseButton .sr-only {
    position: fixed !important;
}

.PhotoHostalCloseButton span:hover {
    opacity: 0.9 !important;
}

.PhotoHostalCloseButton .close {
    opacity: 0.9;
}

.photoUploadIcon {
    margin-top: -270px;
    cursor: pointer;
    pointer-events: none;
}

.toast {
    max-width: none;
    border-radius: 8px;
    border: none;
    box-shadow: none;
}

.toast-header {
    border: none;
}

.AvatarImgUpload > .fileUploader > .fileContainer {
    box-shadow: none;
    border: solid 2px #999;
    max-width: 200px;
    height: 60px;
}

.AvatarImgUpload > .fileUploader > .fileContainer > button {
    position: absolute;
}

.fileContainer {
    box-shadow: none;
    border: none;
    padding: 0 !important;
    background: transparent;
}

.AvatarImgUpload.UserSettings img {
    height: 90px;
    width: 90px;
}

.AvatarImgUpload.UserSettings .fileContainer {
    border: solid 2px white;
    padding: 0 0 20px 0;
}

.AvatarImgUpload.UserSettings .fileContainer .chooseFileButton {
    margin-top: -50px !important;
    border: none !important;
    height: 190px;
    background: none !important;
    color: #0076CC !important;
    text-decoration: underline;
}

.AvatarImgUpload.UserSettings > .fileUploader > .fileContainer {
    box-shadow: none;
    max-width: 200px;
    height: 60px;
}

.fileContainer img {
    box-shadow: none;
    border-radius: 8px;
}

.fileContainer .uploadPictureContainer {
    width: 100%;
    margin: 30px 0 0 0;
    padding: 0;
    background: none;
    box-shadow: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    position: relative;
    border-radius: 8px;
}

.fileContainer .deleteImage {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #f9f9fb;
    background: #383b44;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    line-height: 30px;
    width: 30px;
    height: 30px;
}

.chooseFileButton {
    margin: 0 !important;
    border: solid 2px #505050 !important;
    height: 200px;
    width: 100%;
    max-width: 400px;
    border-radius: 10px !important;
    background: none !important;
    color: #000 !important;
    padding: 50px 0 0 0 !important;
}

.list-group-end-border.list-group-item:last-child {
    border-width: 0 0 1px;
}

.fileContainer .chooseFileButton:hover {
    background-color: #d2d5d8;
}

.embedButton {
    bottom: 45px;
    right: 25px;
    border-radius: 8px;
    margin-top: 130px;
    margin-left: -120px;
}

.custom-mt-nbig {
    margin-top: -55px;
    margin-bottom: 1.1rem !important;
}

.custom_radio {
    margin-bottom: 10px;
}

.custom_radio > input[type=radio] {
    transform: scale(1.5);
    margin: 11px 0 0 -10px;
}

.custom_radio > label {
    margin: 5px 0 0 15px;
}

.phone_info .flag-icon {
    height: 42px !important;
    width: 20px !important;
}

.flag-icon svg {
    border-radius: 90px !important;
    border: solid #dc3545 3px;
}

span[class$="indicatorSeparator"] {
    background-color: white;
}

svg[class*="Svg"] {
    border: solid 1px #7c7979;
    border-radius: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.custom-switch .custom-control-label::after {
    background-color: #dc3545;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #28a745;
}

.custom-control-input:checked ~ .custom-control-label::before {
    background-color: white;
    border: #adb5bd solid 1px;
}

.radioLabel:first-child {
    color: #66cc99;
    position: absolute;
    margin: 0 0 0 5px;
}

.bedType [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.bedType [type=radio] + span {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.5;
}

.bedType [type=radio]:checked + span {
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    filter: grayscale(0);
    opacity: 1;
}

.AvatarImgUpload .deleteImage {
    display: none;
}

.AvatarImgUpload .fileContainer .uploadPictureContainer {
    margin: 30px 0 -60px 0;
}

.AvatarImgUpload .fileContainer .uploadPictureContainer img.uploadPicture {
    width: 160px;
    height: 160px;
    margin-top: -400px;
}

.uploadedPicture {
    width: 400px;
    height: 200px;
    object-fit: cover;
}

.progress {
    width: 100%;
    height: 200px;
    margin: 20px auto;
    border-radius: 10px !important;
}

.progress-bar-animated {
    animation: none !important;
}

.bg-secondary {
    background-color: #d2d5d8 !important;
}

.progress-bar-striped {
    background-image: none;
}

.uploadPicture {
    width: 400px;
    height: 200px;
    object-fit: cover;
}

.fileContainer .uploadIcon {
    width: 50px;
    height: 50px;
    margin-bottom: 40px;
    position: absolute;
    pointer-events: none;
}

.fileContainer .errorsContainer {
    max-width: 300px;
    font-size: 12px;
    color: #dc3545;
    text-align: left;
    position: absolute;
    margin-top: 80px;
}

.DayPickerNavigation {
    display: none;
}

/*.DayPicker_weekHeader {*/
/*margin-left: -150px;*/
/*top: 65px;*/
/*position: fixed;*/
/*background-color: white;*/
/*border: solid .3px #75757545;*/
/*padding-bottom: 6px;*/
/*}*/

.text-info {
    color: #0076CC !important;
    font-size: 15px;
    text-decoration: underline;
}

.sessionClose input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.sessionClose label {
    margin-left: 10px;
    margin-top: 4px;
}

.float-message-notification {
    margin-left: 5px !important;
}

.gm-bundled-control-on-bottom {
    bottom: unset !important;
}

/*body {*/
/*height: 100px !important;*/
/*}*/

.ml-8 {
    margin-left: 5rem !important;
}

.submit-button-align {
    margin: 0;
    position: fixed;
    bottom: 0;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.submit-button-align-calendar {
    position: fixed;
    right: 15px;
    bottom: 30px;
}

.header_bottom_corner_left {
    position: fixed;
    left: 0;
    margin: 64px 0 0 0;
}

.header_bottom_corner_right {
    transform: scale(-1, 1);
    position: fixed;
    right: 0;
    margin: 64px 0 0 0;
}

.container-fluid {
    max-width: 600px;
}

.map-selector {
    position: fixed !important;
}

.servicios span {
    width: 50px;
    text-align: center;
}

.grayscale-img-0 {
    filter: grayscale(100%);
}

.bg-custom {
    background-color: white !important;
}

p.truncate-sms {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
}

.AvatarImgUpload input[type="file"] {
    overflow: auto;
    max-width: 360px;
}

#month-selector .modal-header span {
    border: solid 1px;
    border-radius: 50%;
    padding: 3px 8px 3px;
}

#month-selector span, #month-selector p {
    font-size: 20px;
}

#month-selector .modal-header {
    border-bottom: unset;
}

.react-datepicker, .react-datepicker__month-container {
    width: 100%;
}

/*.react-datepicker__month {*/
/*    margin: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*}*/

.react-datepicker__month-text {
    text-transform: capitalize;
    padding: 15px;
    width: 5rem !important;
    color: #3e3e3e;
    font-size: 18px;
}

.react-datepicker__month-text:focus {
    outline: none;
}

.hostalImageMime button.close span {
    display: none;
}

.hostalImageMime button.close {
    background: url("../images/icons/1x/group_113.png") no-repeat center/100%;
    border: none;
    width: 40px;
    height: 40px;
}

.app-full-menu .modal-header button.customClose {
    background: url("../images/icons/4x/group_113.png") no-repeat center/100%;
    border: none;
    width: 40px;
    height: 40px;
}

.modal-go-back .modal-header button.customClose {
    background: url("../images/icons/1x/group_113.png") no-repeat center/100%;
    border: none;
    width: 24px;
    height: 24px;
}

.reference-upload-div img.photoUploadIcon {
    content: url("../images/icons/4x/group_111.png");
    margin-left: 15px;
}

.reference-upload-div .fileUploader .fileContainer .chooseFileButton {
    padding-top: 5rem !important;
}

button.react-datepicker__navigation--previous {
    background: url("../images/icons/1x/group_112.png") no-repeat center/100%;
    border: none;
    width: 24px;
    height: 24px;
    opacity: 1;
}

button.react-datepicker__navigation--next {
    transform: scale(-1, 1);
    background: url("../images/icons/1x/group_112.png") no-repeat center/100%;
    border: none;
    width: 24px;
    height: 24px;
    opacity: 1;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    font-size: 1.3rem;
}

button.react-datepicker__navigation--previous:focus, button.react-datepicker__navigation--next:focus {
    outline: none;
}

button.react-datepicker__navigation--previous:hover, button.react-datepicker__navigation--next:hover {
    opacity: .75;
}

.react-datepicker__month-text--keyboard-selected {
    background: unset;
}

.react-datepicker__month--selected {
    background-color: #216ba5;
    color: white;
}

.react-datepicker__header {
    height: 44px;
}

.mt-7 {
    margin-top: 5rem !important;
}

.room-color [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.room-color [type=radio] + span {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    opacity: 0.5;
}

.room-color [type=radio]:checked + span {
    opacity: 1;
}

.room-color [type=radio]:checked + span > div {
    background: url("../images/check.png") no-repeat center/100%;
}

.hostalLinkButtons {
    background: white;
    margin-top: 65px !important;
    padding: 10px 0;
}

#borderBottomGradient:after {
    content: "";
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(102, 102, 102, 0.50), rgba(0, 0, 0, 0));
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.msgSendButton:enabled .sendBttnIco {
    border: unset !important;
    fill: white;
    background-color: #525252;
}

.submit-button-align-room {
    position: fixed;
    right: 15px;
    bottom: 30px;
}

.msgPhoto .hostalThumbnail-small {
    height: 30px;
    width: 45px;
    border-radius: 3px !important;
}

.msgPhoto .col-3 {
    max-width: 65px;
}

.msgPhoto .col-4 {
    max-width: 100px;
}

.msgPhoto .hostalThumbnail, .msgPhoto .lock-view {
    height: 50px !important;
    width: 80px !important;
}

.msg-text-truncate {
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.link-li {
    cursor: pointer;
}

.span-add-edit-btn {
    border: 1px solid;
    font-size: 1rem;
    border-radius: 50px;
    padding: .35rem .5rem .35rem .2rem;
    /* color: #3e3e3e; */
}

.btn-light-custom {
    color: #212529;
    background-color: #f2f2f2;
    border-color: #d7d7d7;
}

.btn-light-custom:not(:disabled):not(.disabled).active, .btn-light-custom:not(:disabled):not(.disabled):active, .show>.btn-light-custom.dropdown-toggle {
    color: #212529;
    background-color: #ccc;
    border-color: #d3d9df;
}

.fadeOut {
    /*opacity: 0;
    transition: all ease-out;*/
    display: none;
}

.fadeOut .price-detail-row {
    height: 0
}

.fadeIn {
    /*opacity: 1;
    transition: all .5s ease-in;*/
    display: inline;
}

.fadeIn .price-detail-row {
    height: 1.5rem;
}