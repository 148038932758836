.calendar {
    display: block;
    background: #FFFFFF;
    width: 100%;
    margin: 10px auto;
    font-size: 1.3rem;
    text-align: center;
    /*border-bottom: solid 1px #CCCCCC;*/
}

.calendar .row {
    margin-left: unset;
    margin-right: unset;
}

.calendar header {
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    cursor: default;
    font-size: 1.4rem;
    display: block;
    font-weight: bold;
    text-transform: capitalize;
    user-select: none;
}

.calendar header .month-display {
    /*align-items: center;*/
    height: 40px;
    color: #3e3e3e;
}

.calendar header .arrow {
    text-align: center;
    flex-basis: 15%;
    font-weight: bold;
    cursor: pointer;
    transition: background .2s;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar header .arrow:hover {
    background: #1f5c9d;
}

/*.calendar .week {*/
/*border-top: solid 1px #CCCCCC;*/
/*}*/

/*.calendar .week:first-child {*/
/*    border-top: none;*/
/*}*/

.calendar .day-names {
    color: #6c757d;
    font-weight: bold;
    cursor: default;
    font-size: 1.2rem;
}

.calendar .day-names .day {
    cursor: default;
    align-items: end;
    padding-bottom: 10px;
    border: none !important;
}

.calendar .day-names .day:hover {
    background: inherit;
}

.calendar .day {
    display: flex;
    justify-content: center;
    align-items: start;
    flex: 1;
    cursor: pointer;
    transition: all .2s;
    /*border-radius: 5px;*/
}

@media (max-width: 380px) {
    .calendar {
        margin-left: 10px;
    }

    .calendar .day {
        max-width: 13%;
    }
}

/*@media (min-width: 450px) {*/
/*    .calendar .week .day {*/
/*        min-height: 75px !important;*/
/*    }*/
/*}*/

/*.calendar .week .day {*/
/*    min-height: 75px;*/
/*}*/

/*.calendar .day:hover {*/
/*    background: #EFEFEF;*/
/*}*/

/*.calendar .day:last-child {*/
/*    border-right: 1px solid #CCCCCC;*/
/*}*/

.calendar .day.today {
    background: #dceaf8;
}

.calendar .day.different-month {
    /*color: #C0C0C0;*/
    /*border-top: unset;*/
    pointer-events: none;
    opacity: 0 !important;
}

.calendar .day.selected {
    background: #2875C7;
    color: #FFFFFF;
    opacity: 0.9;
}

.react-datepicker__month-text {
    max-width: 20%;
}

.month-day-number {
    pointer-events: none;
}

.hostalThumbnail {
    object-fit: cover;
    width: 100%;
    height: 80px;
    animation: forwards;
}

.hostalThumbnail-small {
    object-fit: cover;
    width: 100%;
    height: 50px;
    animation: forwards;
}

.blocked-day-line {
    width: 16px;
    height: 27px;
    border-bottom: 1px solid black;
    -webkit-transform: translateY(-22px) translateX(-4px) rotate(-30deg);
    -moz-transform: translateY(-21px) translateX(-4px) rotate(-30deg);
    /*transform: translateY(-21px) translateX(-4px) rotate(-30deg);*/
    /*margin-top: 15px;*/
    margin: 0 auto;
}

.modal-backdrop.show {
    opacity: 0.7 !important;
}
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    padding-top: 20px;
    padding-bottom: 20px;
}
#month-selector .form-group {
    margin-bottom: -5px !important;
}

.react-datepicker__month--disabled {
    cursor: default !important;
    color: #ccc !important;
}